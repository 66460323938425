import React, { useEffect, useState } from 'react';

import styles from './clock.module.scss';

const Clock: React.FC = () => {
  const [time, setTime] = useState('');

  const updateTime = () => {
    const date = new Date();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const nHours = hours < 10 ? `0${hours}` : hours;
    const nMinutes = minutes < 10 ? `0${minutes}` : minutes;
    setTime(`${nHours}:${nMinutes}`);
  };

  useEffect(() => {
    const timerId = setInterval(updateTime, 60000);
    updateTime();
    return () => {
      clearInterval(timerId);
    };
  }, []);

  return <div className={styles['clock']}>{time}</div>;
};

export default Clock;
