import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { Loader } from 'pixi.js';

import { ELoaderStages, ILoaderResource } from '@phoenix7dev/shared-components/dist/loader/d';

import variables from '../assets/styles/export.module.scss';
import { config, red } from '../config';
import { CroonRoundMode, EventTypes, Stage, UserBonus } from '../global.d';
import {
  SetCroonMode,
  setBetAmount,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentStage,
  setSlotConfig,
  setStressful,
  setTalks,
} from '../gql/cache';
import i18n from '../i18next';
import {
  BASE_WIN_AMOUNT_LIMIT,
  BIG_WIN_AMOUNT_LIMIT,
  DOUBLE_WIN_AMOUNT_LIMIT,
  GREAT_WIN_AMOUNT_LIMIT,
  MAXIMUM_FRACTION_DIGITS,
  MEGA_WIN_AMOUNT_LIMIT,
  MINIMUM_FRACTION_DIGITS,
  WinStages,
  eventManager,
} from '../slotMachine/config';

export const wait = (ms: number): Promise<void> => {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
};

export const pixiLoad = (): Promise<Partial<Record<string, PIXI.LoaderResource>>> => {
  return new Promise((resolve, reject) => {
    PIXI.Loader.shared.load((_loader, resources) => {
      const failed = _.filter(resources, (resource) => !!resource?.error);
      if (failed.length) return reject(failed);
      return resolve(resources);
    });
  });
};
export const loadPixiAssets = (assets: PIXI.IAddOptions[], baseUrl: string): Promise<void> => {
  Loader.shared.baseUrl = baseUrl;
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    PIXI.Loader.shared.add(assets);
    let tries = config.failureRetries;
    let success = false;

    while (tries > 0) {
      try {
        tries -= 1;
        await pixiLoad();
        success = true;
        break;
      } catch (err) {
        console.error(err);
      }
    }

    return success ? resolve() : reject();
  });
};

export const loadErrorHandler = (error?: Error, resources?: ILoaderResource[]): void => {
  const stage = resources?.find((r) => !!r.error);
  const errorMsg = stage?.error as unknown as string;
  switch (stage?.name) {
    case ELoaderStages.AUTH:
      if (setStressful().show && setStressful().message === i18n.t(['errors.UNKNOWN.UNKNOWN'])) {
        break;
      }
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t(['errors.CLIENT.INVALID_CLIENT_TOKEN', 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
      break;
    default:
      setStressful({
        show: true,
        type: 'network',
        message:
          (i18n.t([errorMsg === 'Failed to fetch' ? 'errors.UNKNOWN.NETWORK' : 'errors.UNKNOWN.UNKNOWN']) as string) ||
          (error as unknown as string),
      });
  }
};

export const isDevelopment = (): boolean => process.env.NODE_ENV === 'development';

export const isMobilePortrait = (width: number, height: number): boolean => {
  const isPortrait = height >= width;
  const maxWidth = parseInt(variables['breakpointMobilePortraitMax']!, 10);

  return isPortrait && width <= maxWidth;
};

export const calcBottomContainerHeight = (width: number, height: number): number => {
  if (isMobilePortrait(width, height)) {
    return height * (parseInt(variables['bottomHeightPercentMobilePortrait']!, 10) / 100);
  }
  return height * (parseInt(variables['bottomHeightPercent']!, 10) / 100);
};

export const normalizeBalance = (balance = 0): number => {
  return balance / 100;
};

export const normalizeCoins = (coins = 0, coinValue = setCoinValue()): number => {
  return (coins * coinValue) / 100;
};
export const showCurrency = (currency: string): boolean => {
  return currency !== 'FUN';
};
export const formatNumber = (currency = 'FUN', value = 0, showCurrency = false): string => {
  const urlParams = new URLSearchParams(window.location.search);
  const browserLocale = new Intl.Locale(navigator.language || 'us');
  const currentLocale = urlParams.get('lng') || browserLocale.baseName;
  const locale =
    currentLocale.length > 2
      ? currentLocale
      : browserLocale.region
      ? `${currentLocale}-${browserLocale.region}`
      : currentLocale;
  if (currency === 'FUN') {
    return showCurrency
      ? `${currency} ${new Intl.NumberFormat('en', {
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }).format(value)}`
      : new Intl.NumberFormat('en', {
          minimumFractionDigits: MINIMUM_FRACTION_DIGITS,
          maximumFractionDigits: MAXIMUM_FRACTION_DIGITS,
        }).format(value);
  }
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  }).format(value);
};

export const nextTick = (callback: () => void): number => window.setTimeout(callback, 0);

export const getStopReel = (reel: number): number => {
  let stopReelId = 0;
  if (reel === 0) {
    stopReelId = 0;
  } else if (reel === 1) {
    stopReelId = 1;
  } else if (reel === 2) {
    stopReelId = 2;
  }
  return stopReelId;
};

export const updateTextScale = (text: PIXI.Text, maxWidth: number, maxHeight: number, minVal = 1): void => {
  text.scale.set(1, 1);
  text.updateText(true);
  const ratio = Math.min(minVal, Math.min(maxWidth / text.width, maxHeight / text.height));
  text.scale.set(ratio, ratio);
};

export const getCroonRoundModeToRoundNum = (croonRound: CroonRoundMode): number => {
  let round = 1;
  switch (croonRound) {
    case CroonRoundMode.SP1:
      round = 1;
      break;
    case CroonRoundMode.NM3:
    case CroonRoundMode.SP3:
      round = 3;
      if (setCurrentStage() >= 3) {
        round = 10;
      }
      break;
    case CroonRoundMode.NM5:
      round = 5;
      if (setCurrentStage() >= 5) {
        round = 10;
      }
      break;
    case CroonRoundMode.SP5:
      round = 5;
      break;
    case CroonRoundMode.NM10:
      round = 10;
      break;
  }
  return round;
};

// セリフ開始タイミング
export const getStartTalkTiming = (timing: number): number => {
  return Math.floor(Math.random() * timing);
};

// 過去2セリフチェック
export const chkTalkHistory = (word: number): boolean => {
  if (word === 10000) {
    return true;
  }
  return setTalks().some((element) => element === word);
};

// 過去2セリフへ登録
export const setTalkHistory = (word: number): void => {
  setTalks().push(word);
  if (setTalks().length >= 3) {
    setTalks().shift();
  }
};

// セリフ抽選時の繰り返し回避処理
export const repeatAvoidance = (voiceArray: number[]): number => {
  let index = 0;

  for (let i = 0; i < 10; i++) {
    index = Math.floor(Math.random() * voiceArray.length);
    if (!chkTalkHistory(voiceArray[index]!)) {
      // debugDisplay(i + 'th  lot ' + ',history ' + setTalks());
      break;
    } else if (i === 9) {
      debugDisplay(red + i + 'th  lot ' + ',history ' + setTalks());
    }
  }
  setTalkHistory(voiceArray[index]!);
  return voiceArray[index]!;
};

export const countStage = (multiplier: number): WinStages => {
  if (multiplier < DOUBLE_WIN_AMOUNT_LIMIT) {
    return WinStages.None;
  }
  if (multiplier >= DOUBLE_WIN_AMOUNT_LIMIT && multiplier < BASE_WIN_AMOUNT_LIMIT) {
    return WinStages.BaseWin;
  }
  if (multiplier >= BASE_WIN_AMOUNT_LIMIT && multiplier < BIG_WIN_AMOUNT_LIMIT) {
    return WinStages.BigWin;
  }
  if (multiplier >= BIG_WIN_AMOUNT_LIMIT && multiplier < MEGA_WIN_AMOUNT_LIMIT) return WinStages.MegaWin;
  if (multiplier >= MEGA_WIN_AMOUNT_LIMIT && multiplier < GREAT_WIN_AMOUNT_LIMIT) return WinStages.GreatWin;
  return WinStages.EpicWin;
};

export const CheckFinalStage = (state: number): boolean => {
  let rtn = false;
  const mode = SetCroonMode();
  if (
    (mode === 'SP1' && state === 1) ||
    (mode === 'SP3' && state === 3) ||
    (mode === 'SP5' && state === 5) ||
    (mode === 'NM3' && state === 10) ||
    (mode === 'NM5' && state === 10) ||
    (mode === 'NM10' && state === 10)
  ) {
    rtn = true;
  }
  return rtn;
};

export const isDropAnimation = (anim: string): boolean => {
  let rtn = false;
  if (
    anim === '6_1s' ||
    anim === '7_1c' ||
    anim === '8_1t3t2t1s' ||
    anim === '9_2t1s' ||
    anim === '10_2t1c' ||
    anim === '11_2t1t3t1s' ||
    anim === '12_3t2t1s' ||
    anim === '13_3t2t1c' ||
    anim === '14_2s' ||
    anim === '15_2c' ||
    anim === '16_1t3t2s' ||
    anim === '17_1t3t2c' ||
    anim === '18_3t2s' ||
    anim === '19_3t2c' ||
    anim === '20_3s' ||
    anim === '21_3c' ||
    anim === '22_1t3s' ||
    anim === '23_1t3c' ||
    anim === '24_2t1t3s' ||
    anim === '25_2t1t3c' ||
    anim === '26_3t2t1t3s'
  ) {
    rtn = true;
  }
  return rtn;
};

export const getCroonMode = (stage: Stage[]): CroonRoundMode => {
  let croonBonusMode;
  if (stage.length === 1) {
    croonBonusMode = CroonRoundMode.SP1;
  } else if (stage.length === 3) {
    croonBonusMode = CroonRoundMode.SP3;
  } else if (stage.length === 5) {
    croonBonusMode = CroonRoundMode.SP5;
  } else if (stage[2]![0] === 'EXTRA_STAGE_MULTIPLIER_100') {
    croonBonusMode = CroonRoundMode.NM3;
  } else if (stage[4]![0] === 'EXTRA_STAGE_MULTIPLIER_1000') {
    croonBonusMode = CroonRoundMode.NM5;
  } else {
    croonBonusMode = CroonRoundMode.NM10;
  }

  return croonBonusMode;
};

export const debugDisplay = (..._prams: unknown[]): void => {
  // console.log(_prams);
};

export const queryParams = new URLSearchParams(window.location.search);

export const findSubstituteCoinAmount = (requestedCoinAmount: number, coinAmounts: number[]): number => {
  for (let i = coinAmounts.length - 1; i >= 0; i--) {
    const coinAmount = coinAmounts[i]!;

    if (coinAmount <= requestedCoinAmount) {
      return coinAmount;
    }
  }

  return coinAmounts[0] ?? 0; // return 0 if BE send empty arr []
};

// updated coin value from BE after bonus game, because on bonus game we use Coin Value from history
export const updateCoinValueAfterBonuses = (): void => {
  const coinValue = setSlotConfig().clientSettings.coinValues.find((elem) => elem.code === setCurrency())?.variants[0];
  const coinAmount = findSubstituteCoinAmount(setCoinAmount(), setSlotConfig().clientSettings.coinAmounts.default);
  setCoinValue(coinValue);
  setCoinAmount(coinAmount);
  setBetAmount(coinAmount * setSlotConfig().lineSet.coinAmountMultiplier);
  eventManager.emit(EventTypes.UPDATE_BET);
};

export const calcActiveUserBonusTotalWinAmount = (userBonus: UserBonus) => {
  return userBonus.totalWinAmount ? userBonus.totalWinAmount / userBonus.coinValue : 0;
};
