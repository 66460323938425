import { useQuery } from '@apollo/client';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { lobbyBtnAvailable } from '@phoenix7dev/utils-fe';

import { IConfig } from '../../gql/d';
import { configGql } from '../../gql/query';
import { ResourceTypes } from '../../resources.d';
import { queryParams } from '../../utils';
import Resources from '../../utils/resources';

import styles from './info.module.scss';

const ButtonBehaviors: React.FC = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'infoButtonBehaviors' });
  const { data } = useQuery<IConfig>(configGql);
  const { isMobile } = data!;

  return (
    <div className={styles['buttonBehaviors']}>
      <h1 className={styles['title']}>{t('Title')}</h1>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnSpin)} alt="infoBtnSpin" />
        {t('Spin')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnStop)} alt="infoBtnStop" />
        {t('Stop')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnAutoplay)} alt="infoBtnAutoPlay" />
        {t('AutoPlay')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnAutoplayStop)} alt="infoBtnAutoplayStop" />
        {t('AutoPlayStop')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnBetSettings)} alt="infoBtnBetSettings" />
        {t('BetSettings')}
      </div>
      {!isMobile && (
        <div className={styles['buttonBehaviors__item']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnTurbo)} alt="infoBtnTurbo" />
          {t('Turbo')}
        </div>
      )}
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnMenu)} alt="infoBtnMenu" />
        {t('Menu')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnSound)} alt="infoBtnSound" />
        {t('Sound')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.infoBtnInfo)} alt="infoButtonInfo" />
        {t('Info')}
      </div>
      <h1 className={styles['title']}>{t('BetSettingsTitle')}</h1>
      <p
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('BetSettingsText'),
        }}
      />

      <h1 className={styles['title']}>{t('AutoPlaySettingsTitle')}</h1>
      <p
        className={`${styles['p']} ${styles['left']}`}
        dangerouslySetInnerHTML={{
          __html: t('AutoPlaySettingsText'),
        }}
      />
      <div className={styles['buttonBehaviors__item']}>
        <img
          draggable="false"
          className={styles['bigger']}
          src={Resources.getSource(ResourceTypes.buttonOk)}
          alt="infoBtnOk"
        />
        {t('AutoPlayStart')}
      </div>

      <h1 className={styles['title']}>{t('MenuTitle')}</h1>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buttonInfo)} alt="infoBtnInfo" />
        {t('MenuOpen')}
      </div>
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buttonSetting)} alt="infoBtnSetting" />
        {t('MenuSettings')}
      </div>
      {lobbyBtnAvailable(queryParams) && (
        <div className={styles['buttonBehaviors__item']}>
          <img draggable="false" src={Resources.getSource(ResourceTypes.buttonLobby)} alt="infoBtnLobby" />
          {t('MenuLobby')}
        </div>
      )}
      <div className={styles['buttonBehaviors__item']}>
        <img draggable="false" src={Resources.getSource(ResourceTypes.buttonHistory)} alt="infoBtnHistory" />
        {t('MenuHistory')}
      </div>
      <p
        className={[styles['p'], styles['center']]!.join(' ')}
        dangerouslySetInnerHTML={{
          __html: t('Description'),
        }}
      />
    </div>
  );
};

export default ButtonBehaviors;
