/* eslint-disable react-hooks/exhaustive-deps */
import { ApolloClient, useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import _ from 'lodash';
import React, { useCallback, useEffect } from 'react';

import AudioApi from '@phoenix7dev/audio-api';

import { ISongs, blue, config } from '../../config';
import { EventTypes, GameMode, GraphQLErrorsType, ISettledBet, IUserBalance, lineSets } from '../../global.d';
import {
  SetIsCountUp,
  SetIsCroonStageMove,
  setAutoSpinsAmount,
  setAutoSpinsLeft,
  setAutoSpinsStartBalance,
  setBrokenGame,
  setCoinAmount,
  setCoinValue,
  setCurrency,
  setCurrentBonus,
  setFreeRoundBonus,
  setGameHistory,
  setGameMode,
  setIsAutoSpins,
  setIsContinueAutoSpinsAfterFeature,
  setIsFreeSpinsWin,
  setIsInTransition,
  setIsOpenedMessageBanner,
  setIsPopupFreeRoundsOpened,
  setIsRevokeThrowingError,
  setIsShowSoundToast,
  setIsSlotBusy,
  setIsSoundLoading,
  setIsSpinInProgress,
  setIsStopOnAnyWin,
  setIsStopOnBalanceDecrease,
  setIsStopOnBalanceIncrease,
  setIsStopOnFeatureWin,
  setIsStopOnWinExceeds,
  setIsTimeoutErrorMessage,
  setLastRegularWinAmount,
  setReplayBet,
  setReplayFreeSpinBets,
  setSlotConfig,
  setStopOnBalanceDecrease,
  setStopOnBalanceIncrease,
  setStopOnWinExceeds,
  setUserLastBetResult,
  setWinAmount,
} from '../../gql/cache';
import { IConfig, ISlotConfig } from '../../gql/d';
import { placeBetGql } from '../../gql/mutation';
import {
  configGql,
  getAutoSpinsGql,
  getBetAmountGql,
  getGameModeGql,
  getProgressGql,
  getUserGql,
  isStoppedGql,
  replayBetGql,
} from '../../gql/query';
import SlotMachine from '../../slotMachine';
import BgmControl from '../../slotMachine/bgmControl/bgmControl';
import { SlotMachineState, eventManager } from '../../slotMachine/config';
import {
  canPressSpin,
  debugDisplay,
  formatNumber,
  isCroonBonusMode,
  saveReelPosition,
  showCurrency,
} from '../../utils';

import { IPlaceBetInput } from './d';

const handleChangeRestriction = (): void => {
  BgmControl.handleChangeRestriction();
};
const Spin: React.FC = () => {
  const { data } = useQuery<IConfig>(configGql);
  const { isTurboSpin } = data!;
  const { data: dataBet } = useQuery<{ betAmount: number }>(getBetAmountGql);
  const { id: slotId, lineSet } = useReactiveVar<ISlotConfig>(setSlotConfig);
  const isFreeSpinsWin = useReactiveVar<boolean>(setIsFreeSpinsWin);
  const { data: userData } = useQuery<{ user: IUserBalance }>(getUserGql);
  const { data: dataProgress } = useQuery<{
    progress: { status: number; wasLoaded?: boolean };
  }>(getProgressGql);
  const { data: dataSlotStopped } = useQuery<{ isSlotStopped: boolean }>(isStoppedGql);

  const { data: gameModeData } = useQuery<{
    gameMode: GameMode;
  }>(getGameModeGql);
  const { gameMode } = gameModeData!;
  const balanceAmount = userData?.user.balance.amount || 0;
  const winThreeTimes = useReactiveVar<boolean[]>(setGameHistory);

  const { progress } = dataProgress!;

  const betCompleteCallback = (placeBet: ISettledBet, client: ApolloClient<unknown>): void => {
    eventManager.emit(EventTypes.PLACE_BET_COMPLETED);

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const clonedPlaceBet: ISettledBet = JSON.parse(JSON.stringify(placeBet));
    if (clonedPlaceBet.rewards) {
      const replayBonusIndex = clonedPlaceBet?.rewards.findIndex((reward) => reward.__typename === 'ReplayBonusReward');
      if (replayBonusIndex > -1) {
        clonedPlaceBet.rewards[replayBonusIndex]!.__typename = 'BetBonusReward';
      }
    }

    client.writeQuery({
      query: getUserGql,
      data: {
        ...userData,
        user: {
          ...userData?.user,
          balance: placeBet.balance.placed,
        },
      },
    });

    clonedPlaceBet.bet.result.spinResult = [];
    setUserLastBetResult({ ...setUserLastBetResult(), coinValue: clonedPlaceBet.bet.coinValue });

    SlotMachine.getInstance().setResult(clonedPlaceBet);

    if (SlotMachine.getInstance().isStopped) {
      SlotMachine.getInstance().spin(isTurboSpin);
    }
    const callBack = () => {
      const win = placeBet.bet.result.winCoinAmount;
      const lastThreeSpins = [...setGameHistory().slice(1), !!win];
      setGameHistory(lastThreeSpins);
      client.writeQuery({
        query: getUserGql,
        data: {
          ...userData,
          user: {
            ...userData?.user,
            balance: placeBet.balance.settled,
          },
        },
      });
      saveReelPosition(placeBet.bet.result.reelPositions);
    };
    SlotMachine.getInstance().setStopCallback(callBack.bind(this));
  };

  const { data: autoSpins } = useQuery<{
    isAutoSpins: boolean;
    autoSpinsLeft: number;
  }>(getAutoSpinsGql);
  const { isAutoSpins } = autoSpins!;

  const isFreeSpinModeOnTotalWinBannerStep: () => boolean = () =>
    isCroonBonusMode(setGameMode()) && !setCurrentBonus().isActive;

  const [fnGet, { client }] = useMutation<{ placeBet: ISettledBet }, { input: IPlaceBetInput }>(placeBetGql, {
    onError(error) {
      if (error.graphQLErrors.some((err) => err.extensions?.['type'] === GraphQLErrorsType.INSUFFICIENT_FUNDS)) {
        eventManager.emit(EventTypes.RESET_SLOT_MACHINE);
        if (setIsAutoSpins()) setIsAutoSpins(false);
      }
    },

    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const [getReplayBet] = useLazyQuery<{ placeBet: ISettledBet }, { betId: string }>(replayBetGql, {
    async onCompleted({ placeBet }) {
      betCompleteCallback(placeBet, client);
    },
  });

  const setPlayBgm = () => {
    setIsShowSoundToast(false);
    BgmControl.playBgm();
  };

  const onSpin = useCallback(
    (isTurboSpin?: boolean) => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      const spinState = SlotMachine.getInstance().state;
      SlotMachine.getInstance().spin(isTurboSpin);
      if (spinState === SlotMachineState.IDLE && SlotMachine.getInstance().nextResultFlg) {
        if (isCroonBonusMode(setGameMode())) {
          return;
        }

        eventManager.emit(EventTypes.UPDATE_WIN_VALUE, formatNumber(setCurrency(), 0, showCurrency(setCurrency())));
        setWinAmount(0);
        setLastRegularWinAmount(0);
        if (setIsAutoSpins()) setAutoSpinsLeft(setAutoSpinsLeft() - 1);
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        debugDisplay(blue + 'SPIN リクエスト1 ');
        SlotMachine.getInstance().clearNextResult();
        if (setReplayBet()) {
          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          const input = {
            slotId,
            coinAmount: setCoinAmount(),
            coinValue: setCoinValue(),
            lineSetId: lineSets[GameMode.REGULAR],
          } as IPlaceBetInput;
          const freeRoundBonus = setFreeRoundBonus() && setFreeRoundBonus().isActive;
          if (freeRoundBonus) {
            // TODO(FRB) Turn it OFF if you spin during a test
            input.userBonusId = setFreeRoundBonus().id;
          }
          fnGet({
            variables: {
              input,
            },
          });
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
        AudioApi.stop({ type: ISongs.SFX_UI_Close });
        AudioApi.play({ type: ISongs.SFX_UI_SpinStart });
        if (AudioApi.isRestricted) {
          handleChangeRestriction();
        }
      } else {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: true,
          },
        });
      }

      if (AudioApi.isRestricted) {
        AudioApi.changeRestriction(
          false,
          [],
          () => setIsSoundLoading(true),
          () => setPlayBgm(),
        );
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    [dataBet?.betAmount, fnGet, lineSet, slotId],
  );
  useEffect(() => {
    const freeSpin = () => {
      if (setIsRevokeThrowingError() || setIsTimeoutErrorMessage()) return;
      let spinState;
      if (setBrokenGame()) {
        spinState = SlotMachineState.IDLE;
      } else {
        spinState = SlotMachine.getInstance().state;
        SlotMachine.getInstance().spin(isTurboSpin);
      }
      if (spinState === SlotMachineState.IDLE) {
        client.writeQuery({
          query: isStoppedGql,
          data: {
            isSlotStopped: false,
          },
        });
        debugDisplay(blue + 'SPIN リクエスト2');
        if (!setBrokenGame()) {
          SlotMachine.getInstance().clearNextResult();
        }

        if (setReplayBet()) {
          const replayFreeSpins = setReplayFreeSpinBets();
          if (replayFreeSpins.length) {
            setReplayBet(replayFreeSpins[0]);
          }

          getReplayBet({
            variables: { betId: setReplayBet() },
          });
        } else {
          fnGet({
            variables: {
              input: {
                slotId,
                coinAmount: setCoinAmount(),
                coinValue: setCoinValue(),
                lineSetId: lineSets[GameMode.CROON_BONUS]!, // TODO
                userBonusId: setCurrentBonus().id,
              },
            },
          });
        }
        setIsSpinInProgress(true);
        setIsSlotBusy(true);
      } else if (spinState === SlotMachineState.CROON_WINNING) {
        //
      } else {
        if (SlotMachine.getInstance().nextResultFlg) {
          client.writeQuery({
            query: isStoppedGql,
            data: {
              isSlotStopped: true,
            },
          });
        }
      }
    };
    eventManager.on(EventTypes.CROON_STAGE_SPIN, freeSpin);

    return () => {
      eventManager.removeListener(EventTypes.CROON_STAGE_SPIN, freeSpin);
    };
  }, [onSpin, isTurboSpin]);

  const checkAutoSpinSettings = useCallback(() => {
    if (setIsAutoSpins()) {
      const autoSpinsLeft = setAutoSpinsLeft() <= 0;
      const bonus = setIsStopOnFeatureWin() && setCurrentBonus().isActive;
      const stopOnWin = setIsStopOnAnyWin() && (setLastRegularWinAmount() > 0 || setCurrentBonus().isActive);

      const stopOnWinExceeds = setIsStopOnWinExceeds() && setLastRegularWinAmount() >= setStopOnWinExceeds();

      const balanceIncrease =
        setIsStopOnBalanceIncrease() &&
        balanceAmount &&
        setStopOnBalanceIncrease() * setCoinValue() <= balanceAmount - setAutoSpinsStartBalance();

      const balanceDecrease =
        setIsStopOnBalanceDecrease() &&
        balanceAmount &&
        setStopOnBalanceDecrease() * setCoinValue() <= setAutoSpinsStartBalance() - balanceAmount;

      const popupFreeRoundsOpened = setIsPopupFreeRoundsOpened();

      if (
        autoSpinsLeft ||
        bonus ||
        stopOnWin ||
        stopOnWinExceeds ||
        balanceIncrease ||
        balanceDecrease ||
        popupFreeRoundsOpened
      ) {
        setIsAutoSpins(false);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [balanceAmount, onSpin, isTurboSpin]);

  useEffect(() => {
    if (isAutoSpins && setIsFreeSpinsWin()) {
      if (setIsStopOnFeatureWin()) {
        setIsContinueAutoSpinsAfterFeature(false);
        setAutoSpinsLeft(0);
      } else {
        setIsContinueAutoSpinsAfterFeature(true);
      }
      setIsAutoSpins(false);
    }
  }, [isFreeSpinsWin, setIsContinueAutoSpinsAfterFeature()]);

  const onSpinButtonClick = useCallback(() => {
    if (setGameMode() === GameMode.REGULAR && setIsFreeSpinsWin()) {
      return;
    }

    if (setIsOpenedMessageBanner()) {
      // TODO　バックエンドと繋いだ際にはSPIN処理を入れた方がいいかな
      eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
      return;
    }

    if (isAutoSpins) {
      setAutoSpinsLeft(0);
      setIsAutoSpins(false);
      if (!setIsSlotBusy()) {
        //
      }
    } else {
      if (isCroonBonusMode(setGameMode())) {
        if (AudioApi.isRestricted) {
          handleChangeRestriction();
          AudioApi.changeRestriction(
            false,
            [],
            () => setIsSoundLoading(true),
            () => setPlayBgm(),
          );
        }
        eventManager.emit(EventTypes.CROON_STAGE_SPIN);
      } else {
        onSpin(isTurboSpin);
      }
    }
  }, [isAutoSpins, isTurboSpin, onSpin]);

  const useHandleSpaceSpin = useCallback(
    (e: KeyboardEvent) => {
      if (e.keyCode === 32) {
        e.preventDefault();
        e.stopPropagation();

        if (!data?.isEnabledSpaceSpin) {
          return;
        }
        if (
          !canPressSpin({
            gameMode,
            isFreeSpinsWin: setIsFreeSpinsWin(),
            isSpinInProgress: setIsSpinInProgress(),
            isSlotBusy: setIsSlotBusy(),
            isSlotStopped: dataSlotStopped?.isSlotStopped ?? false,
            isOpenedMessageBanner: setIsOpenedMessageBanner(),
            isInTransition: setIsInTransition(),
            isCroonStageMove: SetIsCroonStageMove(),
            isCountUp: SetIsCountUp(),
          }) ||
          setIsPopupFreeRoundsOpened()
        ) {
          return;
        }

        if (setIsOpenedMessageBanner()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          return;
        } else if (SetIsCountUp()) {
          eventManager.emit(EventTypes.SPACEKEY_CLOSE_MESSAGE_BANNER);
          const spinState = SlotMachine.getInstance().state;
          if (spinState !== SlotMachineState.CROON_WINNING) {
            return;
          }
        }

        if (isAutoSpins) {
          checkAutoSpinSettings();
          return;
        }
        if (progress?.wasLoaded && !isFreeSpinModeOnTotalWinBannerStep()) {
          if (isCroonBonusMode(setGameMode())) {
            if (AudioApi.isRestricted) {
              handleChangeRestriction();
              AudioApi.changeRestriction(
                false,
                [],
                () => setIsSoundLoading(true),
                () => setPlayBgm(),
              );
            }
            eventManager.emit(EventTypes.CROON_STAGE_SPIN);
          } else {
            onSpin(isTurboSpin);
          }
        }
      }
    },
    [
      gameMode,
      isAutoSpins,
      dataSlotStopped?.isSlotStopped,
      data?.isEnabledSpaceSpin,
      progress?.wasLoaded,
      checkAutoSpinSettings,
      onSpin,
      isTurboSpin,
    ],
  );

  useEffect(() => {
    window.addEventListener('keydown', useHandleSpaceSpin);
    return () => window.removeEventListener('keydown', useHandleSpaceSpin);
  }, [useHandleSpaceSpin]);

  useEffect(() => {
    const play = _.reduce(winThreeTimes.slice(2), (acc, item) => acc && item);
    const stop = _.reduce(winThreeTimes, (acc, item) => acc || item);
    if (play) {
      //
    }

    if (!stop) {
      //
    }
  }, [winThreeTimes]);

  useEffect(() => {
    let id: NodeJS.Timeout;
    if (!setIsFreeSpinsWin() && setIsContinueAutoSpinsAfterFeature()) {
      setIsAutoSpins(true);
      setIsContinueAutoSpinsAfterFeature(false);
    }
    if (dataSlotStopped?.isSlotStopped) {
      const timeOut = config.autoplay.timeOut;

      id = setTimeout(
        () => {
          checkAutoSpinSettings();
        },
        setAutoSpinsLeft() === setAutoSpinsAmount() ? 0 : timeOut,
      );
    }
    return () => clearTimeout(id);
  }, [isAutoSpins, isFreeSpinsWin, checkAutoSpinSettings, dataSlotStopped?.isSlotStopped]);

  useEffect(() => {
    eventManager.on(EventTypes.TOGGLE_SPIN, () => {
      onSpinButtonClick();
    });

    return () => {
      eventManager.removeListener(EventTypes.TOGGLE_SPIN);
    };
  }, [onSpinButtonClick, isAutoSpins, isTurboSpin]);

  return null;
};

export default Spin;
