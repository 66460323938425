import i18n from 'i18next';
import { DropShadowFilter } from 'pixi-filters';
import TaggedText from 'pixi-tagged-text';
import * as PIXI from 'pixi.js';

import { ResourceTypes } from '../../../resources.d';
import { TweenProperties } from '../../../slotMachine/animations/d';
import Tween from '../../../slotMachine/animations/tween';

import { Dot } from './Dot';
import {
  CAROUSEL_ANIMATION_DURATION,
  CAROUSEL_ARROWS_SIZE,
  CAROUSEL_CONTROLS_COLOR,
  CAROUSEL_DOTS_GAP,
  CAROUSEL_DOTS_SIZE,
} from './config';
import { introStylesInfo } from './styles';

interface ISlide {
  img: ResourceTypes;
  txtKeyBottom: string;
}

class Carousel extends PIXI.Container {
  private slides: ISlide[];

  private slidesContainer: PIXI.Container;

  private controlsContainer: PIXI.Container;

  private dots: PIXI.Container;

  private prevArrow: PIXI.Graphics;

  private nextArrow: PIXI.Graphics;

  private isDragging: boolean;

  private zeroPosition: number;

  private slideWidth: number;

  private slideHeight: number;

  private screenWidth: number;

  private slidesCount: number;

  private slideIndex: number;

  constructor(height: number, slides: ISlide[]) {
    super();
    this.isDragging = false;
    this.zeroPosition = 0;
    this.screenWidth = 0;
    this.slidesCount = 0;
    this.slideIndex = 0;
    this.slideWidth = 0;
    this.slideHeight = 0;
    this.controlsContainer = new PIXI.Container();
    this.slides = slides;
    this.slidesContainer = this.initSlides(height);
    this.prevArrow = this.initPrevArrow();
    this.dots = this.initDots();
    this.nextArrow = this.initNextArrow();
    this.controlsContainer.addChild(this.prevArrow);
    this.controlsContainer.addChild(this.dots);
    this.controlsContainer.addChild(this.nextArrow);
    this.controlsContainer.x = this.controlsContainer.width / 2;
    this.controlsContainer.renderable = this.slides.length > 1;
    this.addChild(this.slidesContainer);
    this.addChild(this.controlsContainer);
  }

  private initSlides = (height: number): PIXI.Container => {
    const slides = new PIXI.Container();
    const widthArr = [];
    const heightArr = [];
    for (let i = 0; i < this.slides.length; i++) {
      const container = new PIXI.Container();
      const texture = PIXI.Texture.from(this.slides[i]!.img);
      const image = new PIXI.Sprite(texture);
      image.anchor.set(0.5, 0);
      image.x = 0;
      image.scale.set(height / image.height);

      const textBottom = new TaggedText(i18n.t<string>(this.slides[i]!.txtKeyBottom), introStylesInfo);

      textBottom.update();
      textBottom.draw();
      textBottom.y = image.height + image.y;
      textBottom.pivot.x = textBottom.defaultStyle.wordWrapWidth! / 2;

      image.y = 0;

      container.addChild(image);
      container.addChild(textBottom);
      widthArr.push(container.width);
      heightArr.push(container.height);
      slides.addChild(container);
    }
    this.slideWidth = Math.max(...widthArr);
    this.slideHeight = Math.max(...heightArr);
    slides.interactive = true;
    slides.buttonMode = true;
    slides.on('pointerdown', this.handleStartDragging);
    slides.on('pointerup', this.handleStopDragging);
    slides.on('pointermove', this.handleDragging);
    slides.on('pointerout', this.handleStopDragging);

    return slides;
  };

  private initDots = (): PIXI.Container => {
    const dotsContainer = new PIXI.Container();
    for (let i = 0; i < this.slides.length; i++) {
      const dot = new Dot(`${i + 1}`, i === 0);
      dot.interactive = true;
      dot.buttonMode = true;
      dot.name = `${i}`;
      dot.x = i * (CAROUSEL_DOTS_SIZE + CAROUSEL_DOTS_GAP);
      dot.on('pointerdown', () => {
        this.handleControls(+dot.name, i);
      });
      dotsContainer.addChild(dot);
    }
    dotsContainer.x = this.prevArrow.width * 2;
    return dotsContainer;
  };

  private createTriangle = (size: number, color: number, direction: 'left' | 'right'): PIXI.Graphics => {
    const triangle = new PIXI.Graphics();
    const dropShadow = new DropShadowFilter({
      color: 0x000000,
      resolution: 2 * PIXI.settings.FILTER_RESOLUTION,
    });
    const triangleWidth = size;
    const triangleHeight = triangleWidth;
    const triangleHalfway = triangleWidth / 2;
    if (direction === 'left') {
      triangle.beginFill(color, 1);
      triangle.lineStyle(0, color, 1);
      triangle.moveTo(triangleWidth, 0);
      triangle.lineTo(triangleHeight, triangleHeight);
      triangle.lineTo(0, triangleHalfway);
      triangle.endFill();
    }
    if (direction === 'right') {
      triangle.beginFill(color, 1);
      triangle.lineStyle(0, color, 1);
      triangle.moveTo(0, 0);
      triangle.lineTo(triangleHeight, triangleHalfway);
      triangle.lineTo(0, triangleHeight);
      triangle.endFill();
    }
    triangle.filters = [dropShadow];

    return triangle;
  };

  private initPrevArrow = (): PIXI.Graphics => {
    const arrow = this.createTriangle(CAROUSEL_ARROWS_SIZE, CAROUSEL_CONTROLS_COLOR, 'left');
    arrow.interactive = true;
    arrow.buttonMode = true;
    arrow.on('pointerdown', () => {
      const slidesCount = this.slidesCount - 1;
      const slideIndex = this.slideIndex - 1;
      this.handleControls(slidesCount, slideIndex);
    });
    return arrow;
  };

  private initNextArrow = (): PIXI.Graphics => {
    const arrow = this.createTriangle(CAROUSEL_ARROWS_SIZE, CAROUSEL_CONTROLS_COLOR, 'right');
    arrow.interactive = true;
    arrow.buttonMode = true;
    arrow.x = this.dots.width - 10 + arrow.width * 3;
    arrow.on('pointerdown', () => {
      const slidesCount = this.slidesCount + 1;
      const slideIndex = this.slideIndex + 1;
      this.handleControls(slidesCount, slideIndex);
    });
    return arrow;
  };

  private handleStartDragging = (eventData: PIXI.InteractionEvent): void => {
    const positions = eventData.data.global;
    this.isDragging = true;
    this.zeroPosition = positions.x;
  };

  private handleStopDragging = (): void => {
    this.isDragging = false;
  };

  private handleDragging = (eventData: PIXI.InteractionEvent): void => {
    if (this.isDragging) {
      const positions = eventData.data.global;
      if (this.zeroPosition > positions.x) {
        const value = this.zeroPosition - positions.x;
        if (value > 20) {
          this.isDragging = false;
          const slidesCount = this.slidesCount + 1;
          const slideIndex = this.slideIndex + 1;
          this.handleControls(slidesCount, slideIndex);
        }
      }
      if (this.zeroPosition < positions.x) {
        const value = positions.x - this.zeroPosition;
        if (value > 20) {
          this.isDragging = false;
          const slidesCount = this.slidesCount - 1;
          const slideIndex = this.slideIndex - 1;
          this.handleControls(slidesCount, slideIndex);
        }
      }
    }
  };

  private handleControls = (slidesCount: number, slideIndex: number): void => {
    if (slideIndex < 0) {
      this.slideIndex = this.slides.length - 1;
    } else if (slideIndex > this.slides.length - 1) {
      this.slideIndex = 0;
    } else {
      this.slideIndex = slideIndex;
    }
    this.dots.children[this.slideIndex]!.name = `${slidesCount}`;

    if (this.slidesCount < slidesCount) {
      if (this.slideIndex === 0) {
        for (let i = 0; i < this.dots.children.length; i++) {
          this.dots.children[i]!.name = `${slidesCount + i}`;
        }
      }
    } else if (this.slideIndex === this.dots.children.length - 1) {
      for (let i = 0; i < this.dots.children.length; i++) {
        this.dots.children[this.slideIndex - i]!.name = `${slidesCount - i}`;
      }
    }

    this.slidesCount = slidesCount;

    this.dots.children[this.slideIndex]!.name = `${this.slidesCount}`;
    this.slidesContainer.children[this.slideIndex]!.x = this.slidesCount * this.screenWidth + this.screenWidth / 2;

    for (let i = 0; i < this.dots.children.length; i++) {
      (this.dots.children[i] as Dot).setActive(i === this.slideIndex);
    }

    const target = this.slidesCount * this.screenWidth;

    const moveXAnimation = new Tween({
      object: this.slidesContainer.pivot,
      property: TweenProperties.X,
      propertyBeginValue: this.slidesContainer.pivot.x,
      target,
      duration: CAROUSEL_ANIMATION_DURATION,
    });
    moveXAnimation.start();
  };

  public setSize = (width: number, height: number, bottomGap: number): void => {
    this.screenWidth = width;
    for (let i = 0; i < this.slides.length; i++) {
      if (width < this.slideWidth) {
        const scale = width / this.slideWidth;
        this.slidesContainer.children[i]!.scale.set(scale);
      }

      const heightScale = (height - bottomGap - this.controlsContainer.height) / this.slideHeight;
      if (width > height) {
        this.slidesContainer.children[i]!.scale.set(Math.min(heightScale, 1));
      }

      this.slidesContainer.children[i]!.transform.position.set(i * width + width / 2, 0);
      this.slidesContainer.children[this.slideIndex]!.x = this.slidesCount * this.screenWidth + this.screenWidth / 2;
    }

    this.controlsContainer.y = this.slidesContainer.height + 15;
    this.controlsContainer.x = width / 2 - this.controlsContainer.width / 2;

    this.slidesContainer.pivot.set(this.slidesCount * width, 0);
  };
}
export default Carousel;
